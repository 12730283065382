import router from '@/router';

[{
        path: '/referral_analytics',
        name: "referral_analytics-home",
        component: () => import( /* webpackChunkName: "referral-analytics" */ './views/ReferralAnalyticsHome'),
        meta: {
            reqAuth: true,
            permission: 'referral_analytics_read'
        },
    },
    {
        path: '/referral_analytics/:id',
        name: "referral_analytics_detail",
        component: () => import( /* webpackChunkName: "referral-analytics" */ './views/ReferralAnalyticsDetail'),
        meta: {
            reqAuth: true,
            permission: 'referral_analytics_read'
        },
    }
].forEach(router.addRoute);