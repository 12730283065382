import router from '@/router';
[{
        path: '/roles',
        name: "role-home",
        component: () => import( /* webpackChunkName: "role" */ './views/RoleHome'),
        meta: {
            reqAuth: true,
            permission: 'role_read'
        },
    },
    {
        path: '/role/create',
        name: "role-create",
        component: () => import( /* webpackChunkName: "role" */ './views/CreateRole'),
        meta: {
            reqAuth: true,
            permission: 'role_create'
        },
    },
    {
        path: '/role/:id',
        name: "role-detail",
        component: () => import( /* webpackChunkName: "role" */ './views/RoleDetail'),
        meta: {
            reqAuth: true,
            permission: 'role_read'
        },
    },
    {
        path: '/role/edit/:id',
        name: "role-edit",
        component: () => import( /* webpackChunkName: "role" */ './views/EditRole'),
        meta: {
            reqAuth: true,
            permission: 'role_update'
        },
    },
].forEach(router.addRoute);