import moment from 'moment';
import BaseClass from "@/common/classes/base-class";
import appConfig from '../services/app-config';

let common = {
    methods: {
        moment: moment,
        uuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        getParamFromURL(url, name) {
            name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(url);
            return results === null ?
                "" :
                decodeURIComponent(results[1].replace(/\+/g, " "));
        },
        getCookie: function (name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        },
        downloadLink: function (url, name) {
            var link = document.createElement("a");
            link.href = url;
            link.download = name;

            //set the visibility hidden so it will not effect on your web-layout
            link.style.visibility = "hidden";

            //this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        },
        /**
         * Returns a random integer between min (inclusive) and max (inclusive).
         * The value is no lower than min (or the next integer greater than min
         * if min isn't an integer) and no greater than max (or the next integer
         * lower than max if max isn't an integer).
         * Using Math.round() will give you a non-uniform distribution!
         */
        randomNumber: function (min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        randomColor: function () {
            return '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
        },
        getFileExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        hasPermission(permission) {
            var permissions = this.$store.state.user.permissions ? this.$store.state.user.permissions : [];
            return permissions.includes(permission);
        },
        getLabel(key) {
            var text = {
                'business_detail': 'Business Detail',
                'business_certificate': 'Business Certificate',
                'business_kyc_details': 'Business KYC Details',
                'business_pan_id': 'Business Pan ID',
                'business_pan_card': 'Business Pan Card',
                'business_aadhar_id': 'Aadhar ID',
                'business_aadhar_card': 'Aadhar Card',
                'business_bank_account_information': 'Business Bank Account Information',
                'bank_account_name': 'Bank Account Name',
                'bank_account_number': 'Bank Account Number',
                'bank_ifsc_code': 'Bank IFSC Code',
                'bank_branch_name': 'Bank Branch Name',
                'amount': 'Request Amount',
                'invoice': 'Invoice',
            };
            return (key in text) ? text[key] : key;
        },
        registrationFieldValidationRule(fieldData) {
            var formValidations = [];

            Array.from(fieldData).forEach((field) => {
                formValidations[field.name] = value => {
                    if ('required' in field.meta && field.meta.required && !value) {
                        return this.getLabel(field.name) + " is required";
                    }

                    // specific input file validation
                    if (field.type == "input_file" && typeof value == "object") {
                        if (!value.length) {
                            return this.getLabel(field.name) + " is required";
                        }
                        if ('file_type' in field.meta && field.meta.file_type != "" && value) {
                          var ext = this.getFileExtension(value[ 0 ].name).toLowerCase();
                          if (!(field.meta.file_type.toLowerCase().split(',')).includes(ext)) {
                                return "Invalid file type, allow only " + field.meta.file_type;
                            }
                        }
                        if ('max' in field.meta && field.meta.max != "" && value) {
                            if (field.meta.max < (value[0].size / 1000)) {
                                return "File size too large, allow only " + field.meta.max + " KB";
                            }
                        }
                    }

                    // specific input number validation
                    if (field.type == "input_number") {
                        if (!(/^\d+$/.test(value))) {
                            return "Allow only number";
                        }
                        if ('digits' in field.meta && field.meta.digits != "" && value) {
                            if (value.length != field.meta.digits) {
                                return "The digits must " + field.meta.digits;
                            }
                        }
                        if ('digits_between' in field.meta && field.meta.digits_between != "" && value) {
                            var between = field.meta.digits_between.split(',');
                            if (value.length < between[0] || value.length > between[1]) {
                                return "The digits must between " + between[0] + " to " + between[1];
                            }
                        }
                    }

                    // specific input text validation
                    if (field.type == "input_text") {
                        if ('size' in field.meta && field.meta.size != "" && value) {
                            if (value.length != field.meta.size) {
                                return "The size must be " + field.meta.size;
                            }
                        }
                    }
                    return true;
                };
            });
            return formValidations;
        },
        localDateTime(dt) {
            return moment(dt).format(appConfig.date_time_format);
        },
        localDate(dt) {
            return moment(dt).format(appConfig.date_format);
        },
        getTicketTypeId(type) {
            return (type in appConfig.ticket_type) ? appConfig.ticket_type[type] : "";
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        defaultFilterCountryId() {
            return appConfig.default_filter_country_id;
        },
        setAuditLogLinks(logs) {
            return logs.map(function (log) {
                var message = log['message'];
                var messageArr = []
                for (;;) {
                    var last = 0;
                    var start = message.indexOf('{{')
                    if (start !== -1) {
                        messageArr.push(message.substring(last, start));
                        last = message.indexOf('}}');
                        var key = message.substring(start+2, last)
                        last = last+2;

                        var name = "";
                        var module = "";
                        if (key.includes("business")){
                            name = log.link_data[key]['business_name'];
                            module = 'partner-detail'
                        }else if (key.includes("user")){
                            name = log.link_data[key]['first_name'] +" "+ log.link_data[key]['last_name'];
                            module = 'partner-detail'
                        }else if (key.includes("ticket")) {
                            name = log.link_data[key]['unique_id'];
                            module = 'ticket-detail';
                        }else if (key.includes("partnerType")) {
                            name = log.link_data[key]['name'];
                            module = 'partner-types-detail'
                        }
                        messageArr.push({
                            'text': name,
                            'module' : module,
                            'id': log.link_data[key]['id']
                        });
                        message = message.substring(last);
                    } else {
                        messageArr.push(message.substring(last));
                        break;
                    }
                }
                log['message_link'] = messageArr
                return log;
            })
        },
        generatePDF(response, name){
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name+'_'+(moment().format('DDMMYYYYhmmss'))+'.pdf');
            document.body.appendChild(link);
            link.click();  
        }
    }
};
common.methods.hideLoader = BaseClass.prototype.hideLoader;
common.methods.showLoader = BaseClass.prototype.showLoader;
common.methods.copyToClipboard = BaseClass.prototype.copyToClipboard;

export default common;