import passbookService from "../../../modules/passbook/services";
import orderService from "../../../modules/order/services";

export default {
    data() {
        return {
            mode: "itemSelect",
            hasSuperAdmin: false,
            products: [],
            groups: [],
            searchKeyword:"",
            filteredGroups:[],
            filteredItemsCount:0,
            bonus: {
                user_id: this.$route.params.userId,
                amount: "",
                description: ""
            },
        }
    },
    mounted() {
        this.getProducts();
    },
    computed: {
        orderedProducts() {
            return this.products.filter(p => p.quantity > 0);
        },
        subTotal() {
            return this.orderedProducts.reduce((previous, current) => {
                let currentPrice = current.line_price * current.quantity;
                return previous + currentPrice;
            }, 0);
        },
        margin() {
            return this.orderedProducts.reduce((previous, current) => {
                let currentPrice = current.margin * current.quantity;
                return previous + currentPrice;
            }, 0);
        },
        grandTotal() {
            return this.subTotal - this.margin;
        },
        adjustment() {
            let rounded = Math.round(this.grandTotal);
            rounded = rounded - this.grandTotal;
            return rounded;
        },
        total() {
            return this.grandTotal + this.adjustment;
        }
    },
    watch:{
        'searchKeyword': function(){
                this.filteredProducts();
        }
    },
    methods: {

        prepareDescription(){
           var descriptionArray = this.orderedProducts.map((product)=>{
                return product.Product_Name + 'x' + product.quantity * product.crm_data.Box_Units;
            })

            return descriptionArray.join(',');
        },

        submitBonusForm() {
            this.showLoader(true);
            this.bonus.amount=this.total;
            this.bonus.description = this.prepareDescription();

            passbookService.addBonus(this.bonus).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
                let menuCLose = document.getElementsByClassName('close-menu')[ 0 ];
                menuCLose.click();
            });
        },
        review() {
            this.mode = "review";
        },
        backToItemList() {
            this.mode = "itemSelect";
        },
        getProducts() {
            orderService.getProducts().then(response => {
                this.hideLoader();
                const allProducts = response.data.map(p => {
                    p.quantity = 0;
                    p.line_price = p.crm_data.Base_Price * (p.crm_data.Box_Units || 1);
                    p.margin = (p.line_price * p.margin_percent / 100);
                    p.after_margin = p.line_price - p.margin;
                    let totalTax = 0;
                    if (p.crm_data.Tax) {
                        p.crm_data.Tax.forEach(i => {
                            totalTax += Number(i.split('-')[ 1 ].split('%')[ 0 ].trim());
                        });
                    }
                    p.tax = (p.after_margin * totalTax / 100);
                    p.after_tax = p.after_margin + p.tax;
                    p.available_quantity = Math.floor(p.crm_data.Qty_in_Stock / (p.crm_data.Box_Units || 1));
                    return p;
                });

                this.products = allProducts.filter(p => p.crm_data.Product_Active);
                this.groups = this.groupBy(this.products, function (p) {
                    let parts = p.Product_Code.split('-');
                    parts.pop();
                    return parts.join('-');
                });
            });
        },
        groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [ item ]);
                } else {
                    collection.push(item);
                }
            });
            return [ ...map ].map(([ name, value ]) => ({ name, value }));
        },
        setQuantity(product, step) {
            if (step == -1 && product.quantity !== 0) {
                product.quantity = product.quantity + step;
            }
            else if (step == 1) {
                product.quantity = product.quantity + step;
            }
        },
        filteredProducts(){
            if(this.searchKeyword == ''){
                this.filteredGroups = this.groups;
                this.filteredItemsCount = 0;
                return ;
            }
            this.filteredItemsCount = 0;
            this.filteredGroups = this.groups.map((group) =>{
                var matchedProducts=group.value.filter((product)=>{
                    return product.Product_Name.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) != -1;
                });
                this.filteredItemsCount += matchedProducts.length;
                return {"name":group.name,"value":matchedProducts};
            })
        },
        clearSelect() {
            this.searchKeyword = '';
        }

    }
}