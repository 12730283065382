import router from '@/router';

[{
        path: '/passbook-users',
        name: "passbook-users",
        component: () => import( /* webpackChunkName: "passbook" */ './views/PassbookUsers'),
        meta: {
            reqAuth: true,
            permission: 'passbook_statement_read'
        },
    },
    {
        path: '/passbook/:userId?',
        name: "passbook-statements",
        component: () => import( /* webpackChunkName: "passbook" */ './views/PassbookStatements'),
        meta: {
            reqAuth: true,
        },
    },
    {
        path: '/passbook-statements/:statementId',
        name: "passbook-statement-details",
        component: () => import( /* webpackChunkName: "passbook" */ './views/PassbookStatementDetails'),
        meta: {
            reqAuth: true,
        },
    },
    {
        path: '/reward-statements/:rewardId',
        name: "reward-statement-details",
        component: () => import( /* webpackChunkName: "passbook" */ './views/RewardStatementDetails'),
        meta: {
            reqAuth: true,
        },
    },

].forEach(router.addRoute);