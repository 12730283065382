import appConfig from "./app-config";
import popper from "./popper";
import notification from "./notification";
import notificationRouting from "./notification-routing";
import firebaseMessaging from '../../firebase'

export default new class {
  register(app) {
    app.config.globalProperties.$appConfig = appConfig;
    app.config.globalProperties.$popper = popper;
    app.config.globalProperties.$notification = notification;
    app.config.globalProperties.$messaging = firebaseMessaging
    app.config.globalProperties.$notificationRouting = notificationRouting

  }
}();