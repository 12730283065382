import router from '@/router';

[{
        path: '/payments',
        name: "payments",
        component: () => import( /* webpackChunkName: "payment" */ './views/PaymentsHome'),
        meta: {
            reqAuth: true,
            permission: 'payment_transaction_read'
        },
    },
    {
        path: '/payments/:payment_id',
        name: "payment-detail",
        component: () => import( /* webpackChunkName: "payment" */ './views/PaymentDetails'),
        meta: {
            reqAuth: true,
            permission: 'payment_transaction_read'
        },
    },


].forEach(router.addRoute);