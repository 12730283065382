export default new class {
    //apiUrl = '//partner-api.lifely.world';  // 'http://partner-api.test';
    apiUrl = process.env.VUE_APP_API_ENDPOINT;
    storeUrl = process.env.VUE_APP_LIFELY_WORLD_URL;
    s3BucketUrl = process.env.VUE_APP_LIFELY_WORLD_S3_URL;
    customerFeedback = process.env.VUE_APP_CUSTOMER_FEEDBACK_URL;
    razorpayApiKey = {
        IN : process.env.VUE_APP_RAZORPAY_IN_API_KEY,
    };
    contentApiUrl = process.env.VUE_APP_CONTENT_API_ENDPOINT;
    date_time_format = "Do-MMM-YYYY, h:mm a"
    date_format = "Do-MMM-YYYY"
    default_filter_country_id = 101
    firebasConfig = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
        vapIdKey: process.env.VUE_APP_FIREBASE_VAP_ID_KEY
    };
    ticket_type = {
        payment_request: 1,
        kyc_update: 2,
        issue: 3,
        bank_detail_update: 4
    }
};