import {
  createStore
} from 'vuex'
import mitt from 'mitt'

var store = createStore({
  state: {
    authToken: null,
    user: null,
    isMobile: false,
    redirectURL: null,
    selectData: null,
    sessionLoaded: false,
    zohoOrderLink: null,
    toast: {
      class: 'toast-tiny toast-top bg-blue-dark',
      message: 'Loading...',
      duration: 3000,
      icon: 'fa-sync fa-spin'
    },
    notification: {
      modal_id: "",
      modal: "",
      title: "",
      body: "",
    }
  },
  mutations: {
    setStateKey(state, keyValue) {
      state[ keyValue.key ] = keyValue.value;
    }
  },
  actions: {
    setStateKey(context, keyValue) {
      context.commit("setStateKey", keyValue);
    },
  },
  modules: {}
})

store.bus = mitt();

export default store;