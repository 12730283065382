import { Chart, registerables } from "chart.js";

export default {
    props: ['options', 'data'],
    data() {
        return {
            componentId: Math.random().toString(36).substring(2, 7),
            chart: []
        }
    },
    watch: {
        'data' :function() {
            if(this.chart[this.$attrs.id]) {
                this.chart[this.$attrs.id].destroy();
            }
            var chartid = document.getElementById(this.$attrs.id)
            this.chart[this.$attrs.id] = new Chart(chartid, {
                type: this.$attrs.type,
                data: this.data,
                options: this.options
            });
        },
    },
    computed: {
        //
    },
    mounted() {
        Chart.register(...registerables);
    },
    methods: {
        //
    }
}