import store from "@/store";

export default new class {
    defaultToast = Object.freeze({
        class: 'toast-tiny toast-top bg-primary-dark w-100 rounded notificationCommonClass',
        message: 'Loading...',
        duration: 0,
        icon: 'fa-sync fa-spin',
        visible: false
    });

    toast = null;

    update(toast) {
        var toastEl = document.getElementById('toaster');
        this.toast = new window.bootstrap.Toast(toastEl, {
            delay: toast.duration
        });

        store.dispatch('setStateKey', {
            key: 'toast',
            value: toast
        });

        if (toast.visible) {
            this.toast.show();
        } else {
            this.toast.hide();
        }
    }


    wait(message) {
        var toast = Object.assign({}, this.defaultToast, {
            message: message || "Please wait...",
            duration: 30000,
            visible: true
        });
        this.update(toast);
    }

    hide() {
        var toast = Object.assign({}, this.defaultToast, {
            visible: false
        });
        this.update(toast);
    }

    info(message) {
        var toast = Object.assign({}, this.defaultToast, {
            message: message,
            class: 'toast-tiny toast-top bg-info rounded text-dark w-100 notificationCommonClass',
            icon: 'fa-info',
            visible: true,
            duration: 3000
        });
        this.update(toast);
    }

    success(message) {
        var toast = Object.assign({}, this.defaultToast, {
            class: 'toast-tiny toast-top bg-success w-100 rounded notificationCommonClass',
            message: message || "Done successfully.",
            icon: 'fa-check',
            visible: true,
            duration: 3000
        });
        this.update(toast);
    }

    error(message) {
        var toast = Object.assign({}, this.defaultToast, {
            class: 'toast-tiny toast-top bg-danger w-100 rounded notificationCommonClass',
            message: message || "Failed!",
            duration: 5000,
            icon: 'fa-exclamation-triangle',
            visible: true
        });
        this.update(toast);
    }

};