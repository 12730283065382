/**
 * Slot example :
 *   <h1 class="header-title">AppKit</h1>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-2"><i class="fas fa-share-alt"></i></a>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-3"><i class="fas fa-share-alt"></i></a>
 */
export default {
    data() {
        return {
            dashboardTabActive: false,
            ordersTabActive: false,
            notificationsTabActive: false,
            partnersTabActive: false
        }
    },
    mounted() {
        window.init_menu();
    },
    created(){
        if (location.pathname.toLowerCase().indexOf('/dashboard') !== -1) {
            this.dashboardTabActive = true;
            this.ordersTabActive = false;
            this.notificationsTabActive = false;
            this.partnersTabActive = false;
        }
        if (location.pathname.toLowerCase().indexOf('/orders') !== -1) {
            this.ordersTabActive = true;
            this.dashboardTabActive = false;
            this.notificationsTabActive = false;
            this.partnersTabActive = false;
        }
        if (location.pathname.toLowerCase().indexOf('/notifications') !== -1) {
            this.notificationsTabActive = true;
            this.dashboardTabActive = false;
            this.ordersTabActive = false;
            this.partnersTabActive = false;
        }
        if (location.pathname.toLowerCase().indexOf('/partners') !== -1) {
            this.partnersTabActive = true;
            this.dashboardTabActive = false;
            this.ordersTabActive = false;
            this.notificationsTabActive = false;
        }
    }
}