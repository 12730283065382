import router from '@/router';

[{
        path: '/partner-types',
        name: "partner-types-home",
        component: () => import( /* webpackChunkName: "partner-types" */ './views/PartnerTypesHome'),
        meta: {
            reqAuth: true,
            permission: 'partner_type_read'
        },
    },
    {
        path: '/partner-types/create',
        name: "partner-types-create",
        component: () => import( /* webpackChunkName: "partner-types" */ './views/CreatePartnerType'),
        meta: {
            reqAuth: true,
            permission: 'partner_type_create'
        },
    },
    {
        path: '/partner-types/:id',
        name: "partner-types-detail",
        component: () => import( /* webpackChunkName: "partner-types" */ './views/PartnerTypeDetail'),
        meta: {
            reqAuth: true,
            permission: 'partner_type_read'
        },
    },
    {
        path: '/partner-types/edit/:id',
        name: "partner-types-edit",
        component: () => import( /* webpackChunkName: "partner-types" */ './views/EditPartnerType'),
        meta: {
            reqAuth: true,
            permission: 'partner_type_update'
        },
    }
].forEach(router.addRoute);