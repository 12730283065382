import router from '@/router';

[{
        path: '/custom-attributes',
        name: "custom-attributes-home",
        component: () => import( /* webpackChunkName: "custom-attribute" */ './views/CustomAttributesHome'),
        meta: {
            reqAuth: true,
            permission: 'custom_attribute_read'
        },
    },
    {
        path: '/custom-attributes/create',
        name: "custom-attributes-create",
        component: () => import( /* webpackChunkName: "custom-attribute" */ './views/CustomAttributesCreate'),
        meta: {
            reqAuth: true,
            permission: 'custom_attribute_create'
        },
    },
    {
        path: '/custom-attributes-detail',
        name: "custom-attributes-detail",
        component: () => import( /* webpackChunkName: "custom-attribute" */ './views/CustomAttributesDetail'),
        meta: {
            reqAuth: true,
            permission: 'custom_attribute_read'
        },
    },
    {
        path: '/custom-attributes/edit',
        name: "custom-attributes-edit",
        component: () => import( /* webpackChunkName: "custom-attribute" */ './views/CustomAttributesEdit'),
        meta: {
            reqAuth: true,
            permission: 'custom_attribute_update'
        },
    },
].forEach(router.addRoute);