import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import PageHeader from './PageHeader';
import FormControl from './FormControl';
import AppSelect from './AppSelect';
import AppFooter from './AppFooter';
import AppChart from './AppChart';
import NavigationBar from './NavigationBar';
import RefundItemPoints from './RefundItemPoints';
//import common from '../mixins/common';
import {
    Field,
    Form
} from 'vee-validate';


export default new class {
    register(app) {
        let components = [
            ['Field', Field],
            ['Form', Form],
            ['AppHeader', AppHeader],
            ['AppMenu', AppMenu],
            ['PageHeader', PageHeader],
            ['FormControl', FormControl],
            ['AppSelect', AppSelect],
            ['AppFooter', AppFooter],
            ['AppChart', AppChart],
            ['NavigationBar', NavigationBar],
            ['RefundItemPoints', RefundItemPoints]
        ];

        components.forEach(entries => app.component(...entries));
    }
}