import router from '@/router';

[{
        path: '/partners',
        name: "partners-home",
        component: () => import( /* webpackChunkName: "partners" */ './views/PartnersHome'),
        meta: {
            reqAuth: true,
            permission: 'partner_read'
        },
    },
    {
        path: '/partners/create',
        name: "partners-create",
        component: () => import( /* webpackChunkName: "partners" */ './views/CreatePartner'),
        meta: {
            reqAuth: true,
            permission: 'partner_create'
        },
    },
    {
        path: '/partners/:id',
        name: "partner-detail",
        component: () => import( /* webpackChunkName: "partners" */ './views/PartnerDetail'),
        meta: {
            reqAuth: true,
            permission: 'partner_read'
        },
    },
    {
        path: '/partners/edit/:id',
        name: "partner-edit",
        component: () => import( /* webpackChunkName: "partners" */ './views/EditPartner'),
        meta: {
            reqAuth: true,
            permission: 'partner_update_personal_detail'
        },
    },
    {
        path: '/partners/edit/:id/custom-margins',
        name: "custom-margins",
        component: () => import( /* webpackChunkName: "partners" */ './views/CustomMargins'),
        meta: {
            reqAuth: true,
            permission: 'partner_update_personal_detail'
        },
    },
    {
        path: '/partners/edit/:id/custom-margins/create',
        name: "custom-margins-create",
        component: () => import( /* webpackChunkName: "partners" */ './views/CreateCustomMargins'),
        meta: {
            reqAuth: true,
            permission: 'partner_update_personal_detail'
        },
    },
    {
        path: '/partners/edit/:id/custom-margins/edit/:mappingId',
        name: "custom-margins-edit",
        component: () => import( /* webpackChunkName: "partners" */ './views/EditCustomMargins'),
        meta: {
            reqAuth: true,
            permission: 'partner_update_personal_detail'
        },
    },
].forEach(router.addRoute);