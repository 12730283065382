import router from '@/router';
[{
        path: '/cluster-admins',
        name: "cluster-admin-home",
        component: () => import( /* webpackChunkName: "cluster-admin" */ './views/ClusterAdminHome'),
        meta: {
            reqAuth: true,
            permission: 'cluster_admin_read'
        },
    },
    {
        path: '/cluster-admins/create',
        name: "cluster-admin-create",
        component: () => import( /* webpackChunkName: "cluster-admin" */ './views/CreateClusterAdmin'),
        meta: {
            reqAuth: true,
            permission: 'cluster_admin_create'
        },
    },
    {
        path: '/cluster-admins/:id',
        name: "cluster-admin-detail",
        component: () => import( /* webpackChunkName: "cluster-admin" */ './views/ClusterAdminDetail'),
        meta: {
            reqAuth: true,
            permission: 'cluster_admin_read'
        },
    },
    {
        path: '/cluster-admins/edit/:id',
        name: "cluster-admin-edit",
        component: () => import( /* webpackChunkName: "cluster-admin" */ './views/EditClusterAdmin'),
        meta: {
            reqAuth: true,
            permission: 'cluster_admin_update'
        },
    },
    {
        path: '/cluster-admin-register',
        name: "cluster-admin-register",
        component: () => import( /* webpackChunkName: "cluster-admin" */ './views/ClusterAdminRegister'),
        meta: {
            reqAuth: false,
        },
    }
].forEach(router.addRoute);