import firebase from 'firebase/app'
import { } from 'firebase/firebase-messaging' //do not touch this line, consult Dhrumil
var firebaseConfig = {
    apiKey: "AIzaSyBgKTb9owtf04rrhypE-0TV3NmkAXrZmik",
    authDomain: "staging-partner-lifely-world.firebaseapp.com",
    projectId: "staging-partner-lifely-world",
    storageBucket: "staging-partner-lifely-world.appspot.com",
    messagingSenderId: "955702966760",
    appId: "1:955702966760:web:aed2fd6016b276021b0c5d",
    measurementId: "G-V1EV5QK6NE"
}
let messaging = null;
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();

}
export default messaging