import firebaseMessaging from "../firebase"
import notification from "@/common/services/notification";
// import userData from "../modules/user/services/user-data";
// import appConfig from "@/common/services/app-config";

export default {
	data() {
		return {
			messaging: firebaseMessaging,
			partnerData: null,
			registration: null,
			isRefresh: false,
			refreshing: false
		};
	},

	mounted() {
		window.init_template();
		window.oncontextmenu = function () {
			return false;
		};
		this.detectDevice();
	},
	created() {
		// this.requestNotificationPermission();
		if (this.messaging != null) {
			this.messaging.onMessage((payload) => {
				console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
				notification.successNotification(payload);
			});
		}
		document.addEventListener(
			'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
		);
		navigator.serviceWorker.addEventListener(
			'controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				console.log('--will refresh app now--');
				window.location.reload();
			}
		);
		//this.initZohoForm();
	},
	methods: {
		appUpdateUI: function (e) {
			this.registration = e.detail;
			this.isRefresh = true;
			document.getElementById('newVersionBlocker').style.display = 'block';
		},
		update() {
			this.isRefresh = false;
			if (this.registration || this.registration.waiting) {
				this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			}
		},

		detectDevice() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				// true for mobile device
				this.$store.dispatch("setStateKey", {
					key: "isMobile",
					value: true
				});
			} else {
				// false for not mobile device
				this.$store.dispatch("setStateKey", {
					key: "isMobile",
					value: false
				});
			}
		}
		// initZohoForm() {
		// 	this.$store.bus.on("user", (value) => {
		// 		if (value) {
		// 			userData.getProfileDetail().then(response => {
		// 				this.partnerData = response.data.data;
		// 				let link = `${appConfig.orderFormUrl}?email=${this.$store.state.user.email}&phone=${this.$store.state.user.phone}&business_name=${this.$store.state.user.business_name}&name=${this.$store.state.user.full_name}&id=${this.$store.state.user.id}`;
		// 				link += `&address=${this.partnerData.address}&city=${this.partnerData.city}&state=${this.partnerData.state}&country=${this.partnerData.country}&zipcode=${this.partnerData.zipcode}&margin_percent=${this.partnerData.margin_percent}`;
		// 				this.$store.dispatch("setStateKey", {
		// 					key: "zohoOrderLink",
		// 					value: link
		// 				});
		// 			});
		// 		}
		// 	});
		// }
		// requestNotificationPermission() {

		// 	Notification.requestPermission((result) => {
		// 		if (result === 'denied') {
		// 			alert('Please allow notification in Browser to receive alerts');
		// 		} else if (result === 'default') {
		// 			alert('Please allow notification in Browser to receive alerts');
		// 		}
		// 		console.log('Permission was granted for notifications');
		// 		this.messaging.getToken().then((token) => {
		// 			console.log('New token created: ', token)
		// 		})
		// 	});
		// }
	}

};