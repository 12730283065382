<label :for="$attrs.name" class="color-primary-dark font-13 font-600 font-13 font-600 rounded-m" style="z-index: 20;" v-if="$attrs.placeholder">{{ $attrs.placeholder}}</label>
<Field v-bind="$attrs" as="select" class="form-select app-select" v-model="selected">
    <option value="">{{ $attrs.placeholder}}</option>
    <option v-for='opt in options' :value="valueProp ? opt[valueProp] : opt">
        <slot :item="opt"></slot>
    </option>
</Field>
<div class="input-overlay" :class="{'multi-select': isMultiple}" :data-menu="componentId" v-if="!$attrs.disabled" @click="openModal">
    <div v-if="isMultiple">
        {{ multiSelected.length ? multiSelected.length : "" }} {{$attrs.placeholder}} {{ multiSelected.length ? 'selected' : ''}}
    </div>
</div>

<teleport to="#appSelectSection">
    <div :id="componentId" class="menu menu-box-bottom rounded-m" data-menu-width="cover" data-menu-effect="menu-over">
        <div class="menu-title p-3 pb-2">
            <!-- <p class="color-highlight"></p> -->
            <h2 class="letterSpacing font-17 font-200 text-capitalize">Select {{ $attrs.placeholder || $attrs.name}}</h2>
            <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
        </div>
        <div class="search-section" v-if="disableSearchBar == false || disableSearchBar == 'false'">
            <div class="input-style has-borders">
                <input class="form-control" type="text" placeholder="Search " v-model="search">
                <em></em>
                <i class="disabled invalid"></i>
                <i class="disabled valid"></i>
            </div>
        </div>
        <div v-if="disableClearSelection == false || disableClearSelection == 'false'">
            <h5 v-if="selected" role="button" @click="clearSelect()" class="ms-2 letterSpacing font-400 font-14">
                <i class="fa fa-times-circle m-2 color-red-dark"></i> Clear Selection
            </h5>
        </div>

        <div class="divider divider-margins mt-3 mb-0"></div>
        <div class="content mt-0">
            <div ref="opts" class="list-group list-custom-small pe-2 pb-4">
                <a href="#" v-for='opt in options' @click="selectOption(opt)" class="ms-2 color-theme letterSpacing">
                    <slot v-if='!!$slots.display' name="display" :item="opt"></slot>
                    <slot v-else :item="opt"></slot>
                    <i v-if="isMultiple && multiSelected.includes(opt[valueProp])" class="fa fa-check-circle color-highlight font-15"></i>
                </a>
            </div>
        </div>
    </div>
</teleport>