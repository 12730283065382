import axios from "axios";
import appConfig from "./app-config";
import store from "@/store";
import router from "@/router";
import helper from "@/helper";
import popper from "./popper";
import BaseClass from "@/common/classes/base-class";

export default new class extends BaseClass {
  apiUrl = appConfig.apiUrl; // 'http://localhost:1525';
  authToken = null;

  constructor() {
    super();
    store.bus.on("authToken", authToken => {
      this.authToken = authToken;
    });
  }

  instance = axios.create({
    headers: {
      Accept: "application/json",
      // 'Content-Type': "multipart/form-data"
    },
    baseURL: this.apiUrl,
    // withCredentials: true,
    timeout: 0
  });

  requestInterceptor = this.instance.interceptors.request.use(config => {
    if (this.authToken) {
      config.headers['Authorization'] = `Bearer ${this.authToken.access_token}`;
    }

    return config;
  }, Promise.reject);

  responseInterceptor = this.instance.interceptors.response.use(
    response => response,
    error => {
      console.log(error.response);
      if (error && error.response && error.response.data) {
        var message = error.response.data.message;
        if (error.response.status == 422) {
          var msg = Object.values(error.response.data.errors).map(function (item) {
            return item[0];
          })
          popper.error(msg.join(','));
        } else if (error.response.status == 503) {
          popper.error("We are upgrading this part of application at the moment. It will be available soon...");
        } else if (message) {
          popper.error(message);
        }
      }
      this.hideLoader();
      this.handleRedirection(error);
      return Promise.reject(error);
    }
  );

  handleRedirection(error) {
    var status = error.response ? error.response.status : 500;
    switch (status) {
      case 401:
        helper.terminateSession();
        router.push({
          name: "login"
        });
        break;
    }
  }
}();