<div class="menu-title p-3 pb-2" v-if="mode == 'itemSelect'">
    <h2 class="letterSpacing font-500">Refund By Item</h2>
    <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
</div>
<div class="menu-title p-3 pb-2" v-if="mode == 'review'">
    <button @click="backToItemList" class="font-500 letterSpacing font-13 text-dark">
        <i class="fa fa-arrow-left font-12 me-2"></i>
        Back
    </button>
    <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
</div>
<div class="shadow-0 rounded-0 mb-0" v-if="mode == 'itemSelect'">
    <form-control class="has-icon mx-3">
        <Field as="input" type="text" name="search" v-model="searchKeyword" style="background-color: white !important;"
            class="form-control letterSpacing" id="search" placeholder="Search"></Field>
        <i class="fa fa-times color-red-dark clearSearch font-16" @click="clearSelect()" v-if="searchKeyword"></i>
    </form-control>


    <h5 class="text-center letterSpacing text-secondary font-500" v-if="filteredItemsCount > 0">{{ filteredItemsCount }}
        {{ filteredItemsCount > 1 ? 'Items Found' : 'Item Found' }}</h5>
    <div class="itemListContainer mx-2 pb-5">
        <div v-for="group in (searchKeyword==''?groups:filteredGroups)" :key="group.key">
            <div class="card card-style mb-3 mt-3 mx-2" v-if="group.value.length > 0">
                <div class="content mb-1">
                    <div v-for="(product,index) in group.value" :key="product.id">
                        <div class="border-top mb-2" v-if="index != 0"></div>
                        <div class="ml-3">
                            <h5 class="letterSpacing font-15 font-500 d-inline">
                                {{product.Product_Name}}
                            </h5>

                            <div class="d-flex mt-3 mb-2 justify-content-between align-items-center">
                                <h1 class="m-0 p-0 font-18 font-500 letterSpacing text-success">
                                    {{product.crm_data.$currency_symbol}}
                                    {{product.crm_data.Base_Price*(product.crm_data.Box_Units || 1)}}
                                    <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units ==1">
                                        ({{product.crm_data.Box_Units}} unit)
                                    </span>
                                    <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                                        ({{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}})
                                    </span>
                                </h1>
                                <div>
                                    <div class="d-flex" v-if="product.crm_data.Qty_in_Stock > 0">
                                        <small class="p-2 font-600 letterSpacing text-secondary">
                                            QTY.
                                        </small>
                                        <div class="rounded-s d-flex justify-content-center align-items-center">
                                            <a href="javascript:;" @click="setQuantity(product, -1)">
                                                <div class="minusIcon">
                                                    <i class="fa fa-minus font-10"
                                                        :class="[product.quantity > 0 ? 'primary-alt-base-text' : 'text-secondary']"></i>
                                                </div>
                                            </a>
                                            <input :id="'quantity_'+product.id"
                                                class="fw-bolder border-bottom border-top-0 border-start-0 border-end-0 text-center border-secondary"
                                                type="number" min="0" :max="product.available_quantity"
                                                v-model="product.quantity">
                                            <a href="javascript:;" @click="setQuantity(product, 1)">
                                                <div class="plusIcon">
                                                    <i class="fa fa-plus font-10 primary-alt-base-text"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="reviewActionContainer" v-if="mode == 'itemSelect'">
    <div class="card mb-4" :class="[ orderedProducts.length > 0 ? 'availableItem' : 'unAvailableItem' ]">
        <div class="content">
            <div class="d-flex justify-content-center align-items-center mb-2">
                <div class="ml-auto itemQtyNumberContainer fontWeight">
                    <h4 class="font-20 mb-0">{{orderedProducts.length}}</h4>
                </div>
                <div class="mr-3">
                    <h4 class="font-20 letterSpacing mb-0 fontWeight ms-2">items</h4>
                </div>
            </div>
            <div class="divider mb-3"></div>
            <button @click="review"
                class="btn btn-full btn-m text-uppercase font-800 rounded-sm mb-3 letterSpacing primary-light-bg w-100">
                Review Items
            </button>
        </div>
    </div>
</div>

<div class="shadow-0 rounded-0 mb-0" v-if="mode == 'review'">
    <div class="itemListContainer mx-2 pb-5">
        <div class="card card-style mb-3 mt-3" v-for="product in orderedProducts" :key="product.id">
            <div class="content mb-0">
                <div class="ml-3">
                    <h5 class="letterSpacing font-15">{{product.Product_Name}}</h5>
                    <span class="primary-alt-light-text fw-bold "
                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units == 1">
                        x {{product.crm_data.Box_Units}} unit
                    </span>
                    <span class="primary-alt-light-text fw-bold"
                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                        x {{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}}
                    </span>

                    <div class="d-flex mt-3 mb-4 justify-content-between">
                        <h1 class="font-18 text-success">{{product.crm_data.$currency_symbol}}
                            {{product.crm_data.Base_Price*(product.crm_data.Box_Units || 1)}}</h1>
                        <div>
                            <div class="d-flex">
                                <small class="p-2 letterSpacing text-dark">Qty.</small>
                                <div class="stepper rounded-s float-left">
                                    <a href="javascript:;" @click="setQuantity(product, -1)"><i
                                            class="fa fa-minus color-theme opacity-40"></i></a>
                                    <input :id="'quantity_'+product.id" class="fw-bolder" type="number" min="1"
                                        max="1000" v-model="product.quantity">
                                    <a href="javascript:;" @click="setQuantity(product, 1)"><i
                                            class="fa fa-plus color-theme opacity-40"></i></a>
                                </div>
                                <small class="p-2">{{product.crm_data.Usage_Unit}}</small>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card reviewActionContainer" v-if="mode == 'review' && orderedProducts.length > 0">
    <div class="content">
        <div class="d-flex mb-2 justify-content-around">
            <div class="mr-3">
                <h4 class="font-20 letterSpacing mb-0 fontWeight">Total Amount</h4>
            </div>
            <div class="ml-auto">
                <h4 class="font-20 letterSpacing text-success fontWeight">
                    {{orderedProducts[0].crm_data.$currency_symbol}}{{total.toFixed(2)}}
                </h4>
            </div>
            <a href="#billingSummary">
                <i class="fas fa-chevron-down primary-light-text"></i>
            </a>
        </div>
        <div class="divider mb-3"></div>
        <button @click="submitBonusForm"
            class="btn btn-full btn-m text-uppercase mb-3 font-800 rounded-sm letterSpacing primary-light-bg w-100">
            Add Credit
        </button>
    </div>
</div>