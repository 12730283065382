import api from "../../services/api";
import helper from "@/helper";
// import store from "../../../store";
export default {
    data() {
        return {
            hasSuperAdmin: false,
            userStatus: "",
            whatsappLink: "",
            facebookLink: "",
            emailLink: "",
            twitterLink: "",
            linkedinLink: "",
        }
    },
    computed: {
        'user': function () {
            return this.$store.state.user;
        }
    },
    watch: {
        'user': function () {
            if ('user' in this.$store.state && this.$store.state.user != null) {
                var link = this.$store.state.user.referral_link;
                this.whatsappLink = "whatsapp://send?text=" + link;
                this.facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + link;
                this.emailLink = "mailto:?body=" + link;
                this.linkedinLink = "https://www.linkedin.com/shareArticle?mini=true&url=" + link;
                this.twitterLink = "https://twitter.com/home?status=" + link;
                if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
                    this.hasSuperAdmin = true;
                }
            }
        }
    },
    mounted() {
        window.init_menu();
    },
    
    methods: {
        logoutUser() {
            this.showLoader(true);
            api.instance.post('/logout').then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
                helper.terminateSession();
                this.$router.push({
                    name: 'home'
                });
            });
        },
        doCopy: function () {
            let selectEl = document.getElementsByClassName('copyLinkInput')[ 0 ];
            selectEl.select();
            document.execCommand("copy");
            this.$popper.success('Referral Link Copied');
        }
    }
}