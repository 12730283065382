import store from "./store";
import axios from "axios";
import appConfig from "@/common/services/app-config";
import BaseClass from "@/common/classes/base-class";
import popper from "@/common/services/popper";

export default new class {
    setupAuthListeners() {
        store.bus.on("authToken", function (authToken) {
            store.dispatch("setStateKey", {
                key: "authToken",
                value: authToken
            });
        });
        store.bus.on("user", function (user) {
            store.dispatch("setStateKey", {
                key: "user",
                value: user
            });

            if (user && location.href.indexOf('localhost') < 0) {
                /** Fire user logged in anayltics calls here if any */
            }
        });
    }

    validateAuthorization() {
        let authToken = localStorage.getItem("authToken");
        if (authToken) {
            let data = JSON.parse(authToken);
            // let expires = new Date(authToken["expires"]);
            /** Switch to condition when expires is available */
            store.bus.emit("authToken", data);
            // console.log(expires);
            // if (new Date() < expires) {
            //     store.bus.emit("authToken", authToken);
            // }

            return axios.get("/get-details", {
                baseURL: appConfig.apiUrl,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${ data.access_token }`
                }
            }).then((response) => {
                store.bus.emit('user', response.data);
            }).catch(() => {
                console.log("Token expire.");
            });
        } else {
            return Promise.resolve();
        }
    }

    rememberAuthorization() {
        localStorage.setItem("authToken", JSON.stringify(store.state.authToken));
    }

    terminateSession() {
        store.bus.emit("authToken", null);
        store.bus.emit("user", null);
        localStorage.removeItem("authToken");
    }

    getSelectData(data) {
        return axios.get("/get-select-data", {
            baseURL: appConfig.apiUrl,
            params: data,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${ data.access_token }`
            }
        }).then((response) => {
            if (store.state.selectData) {
                store.state.selectData = { ...store.state.selectData, ...response.data };
            } else {
                store.state.selectData = response.data;
            }
        }).catch(() => {
            console.log("No select data available.");
        });
    }

    async navigatorShare(navigatorShareData) {
        BaseClass.prototype.showLoader(true);
        try {
            await fetch(navigatorShareData.imageUrl, {
                cache: 'no-cache'
            })
                .then((res => res.blob()))
                .then((blobRes) => {
                    BaseClass.prototype.hideLoader();

                    let files = [ new File([ blobRes ], navigatorShareData.imageTitle, { type: blobRes.type }) ];
                    const shareData = {
                        title: navigatorShareData.shareTitle ? navigatorShareData.shareTitle : '',
                        text: navigatorShareData.shareText ? navigatorShareData.shareText : '',
                        files
                    };
                    if (navigator.canShare(shareData)) {
                        try {
                            navigator.share(shareData);
                        } catch (err) {
                            if (err.name !== 'AbortError') {
                                console.error(err.name, err.message);
                            }
                        }
                    } else {
                        popper.error('Sorry,Sharing not supported');
                    }
                })
        } catch (error) {
            popper.error('something went wrong!');
        }
        finally {
            BaseClass.prototype.hideLoader();
        }
    }
}();