import router from '@/router';

[{
    path: '/user/login',
    name: "login",
    component: () => import( /* webpackChunkName: "user" */ './views/Login'),
    meta: {
        reqAuth: false,
    },
},
{
    path: '/user/impersonate',
    name: "impersonate",
    component: () => import( /* webpackChunkName: "user" */ './views/Impersonate'),
    meta: {
        reqAuth: false,
    },
},
{
    path: '/user/request',
    name: "request",
    component: () => import( /* webpackChunkName: "user" */ './views/Request'),
    meta: {
        reqAuth: false,
    },
},
{
    path: '/user/register',
    name: "register",
    component: () => import( /* webpackChunkName: "user" */ './views/Register'),
    meta: {
        reqAuth: false,
    },
},
{
    path: '/user/profile',
    name: "profile",
    component: () => import( /* webpackChunkName: "profile" */ './views/Profile'),
    meta: {
        reqAuth: true,
    },
},
{
    path: '/user/profile-edit',
    name: "profile-edit",
    component: () => import( /* webpackChunkName: "profile" */ './views/ProfileEdit'),
    meta: {
        reqAuth: true,
    },
}].forEach(router.addRoute);