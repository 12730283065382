/* eslint-disable no-console */

import {
  register
} from 'register-service-worker'
import firebase from 'firebase/app'
import { } from 'firebase/firebase-messaging'
if (firebase.messaging.isSupported()) {
  // register(`${ process.env.BASE_URL }firebase-messaging-sw.js`, {
  //   ready () {
  //     console.log(
  //       'Firebase Service Worker ready'
  //     )
  //   },
  //   registered () {
  //     console.log('Firebase Service worker has been registered.')
  //   },
  //   cached () {
  //     console.log('Firebase Content has been cached for offline use.')
  //   },
  //   updatefound () {
  //     console.log('Firebase New content is downloading.')
  //   },
  //   updated () {
  //     console.log('Firebase New content is available; please refresh.')
  //   },
  //   offline () {
  //     console.log('Firebase No internet connection found. App is running in offline mode.')
  //   },
  //   error (error) {
  //     console.error('Error during Firebase service worker registration:', error)
  //   }
  // })
}
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}_service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound(registration) {
      console.log('New content is downloading.');
      console.log(registration);

    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      console.log(registration);
      document.dispatchEvent(
        new CustomEvent('serviceWorkerUpdateEvent', { detail: registration })
      );
      //showNewversion();
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}