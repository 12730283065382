<!-- <button  @click="update">Update</button> -->
<a href="javascript:void(0)" @click="update" 
class="new-version-message bg-green-dark color-white" :class="{'new-version-message-active':isRefresh}" style="color:#ffffff;cursor:pointer;z-index:10001;">Click to update for Latest App version</a>
<router-view :key="$route.path"></router-view>

<div id="toaster" :class="$store.state.toast.class" class="toast" data-bs-autohide="true"><i class="fa me-3" :class="$store.state.toast.icon"></i>
    {{$store.state.toast.message}}
</div>

<div id="appSelectSection"></div>

<!-- Main Menu-->
<app-menu />