import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getProducts(data) {
        return api.instance.get(this.baseUrl + '/crm-products', {
            params: data
        });
    }
};