import router from '@/router';

[{
        path: '/reports',
        name: "report-home",
        component: () => import( /* webpackChunkName: "partners" */ './views/ReportsHome'),
        meta: {
            reqAuth: true,
        },
    },
    {
        path: '/report-config',
        name: "report-config",
        component: () => import( /* webpackChunkName: "partners" */ './views/ReportsConfig'),
        meta: {
            reqAuth: true,
        },
    },

].forEach(router.addRoute);