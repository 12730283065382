/**
 * Slot example :
 *   <h1 class="header-title">AppKit</h1>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-2"><i class="fas fa-share-alt"></i></a>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-3"><i class="fas fa-share-alt"></i></a>
 */
export default {
    mounted() {
        window.init_menu();
    },
    props: {
        menu: {
            type: String,
            default: "yes"
        }
    }
}