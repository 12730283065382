import router from '@/router';

[{
        path: '/',
        name: "home",
        component: () => import( /* webpackChunkName: "public" */ './views/Home'),
        meta: {
            reqAuth: false,
        },
    },
    {
        path: '/partner-policy',
        name: "partner-policy",
        component: () => import( /* webpackChunkName: "public" */ './views/partner-policy'),
        meta: {
            reqAuth: false,
        },
    },
    {
        path: '/faq',
        name: "faq",
        component: () => import( /* webpackChunkName: "public" */ './views/faqs'),
        meta: {
            reqAuth: false,
        },
    }
].forEach(router.addRoute);