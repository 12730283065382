import router from "../../router"
export default new class {
    defualtToast = Object.freeze({
        class: 'toast-tiny toast-top bg-primary-dark w-100 rounded',
        message: 'Loading...',
        duration: 3000,
        icon: 'fa-sync fa-spin',
        visible: false
    });

    toast = null;

    updateNotification(toast) {
        var notificationId = Math.random().toString(36).substring(2, 7);
        var notificationElement = document.createElement("div");
        notificationElement.setAttribute("id", "notification-" + notificationId);
        notificationElement.setAttribute("data-dismiss", "notification-" + notificationId);
        notificationElement.setAttribute("data-bs-delay", 3000);
        notificationElement.setAttribute("data-bs-autohide", false);
        notificationElement.setAttribute("class", "notification notification-ios bg-dark-dark ms-2 me-2 mt-2 rounded-s");
        notificationElement.innerHTML = '<span class="notification-icon"><i class="fa fa-bell color-white font-14"></i><em>' +
            toast.message.notification.title +
            '</em><button type="button"  class="ms-auto float-end " data-bs-dismiss="toast" aria-label="Close" ><i class="fas fa-times text-light font-14"></i></button></span><p class="">' +
            toast.message.notification.body +
            '</p>';
        document.body.appendChild(notificationElement);

        var toastEl = document.getElementById('notification-' + notificationId);
        toastEl.addEventListener("click", () => {
            console.log('clicked');
            toastEle.hide();
            switch (toast.message.data.modal) {
                case 'customer': {

                    router.push({
                        name: 'customer-detail',
                        params: {
                            id: toast.message.data.modal_id
                        }
                    });
                }
                break;
            }
        });

        var toastEle = new window.bootstrap.Toast(toastEl, {
            delay: toast.duration
        });
        console.log(toast);
        if (toast.visible) {
            toastEle.show();
        } else {
            toastEle.hide();
        }
    }

    successNotification(notification) {
        var toast = Object.assign({}, this.defualtToast, {
            class: 'toast-tiny toast-top bg-success w-100 rounded',
            message: notification || {},
            icon: 'fa-check',
            visible: true,
            duration: 3000
        });
        this.updateNotification(toast);
    }
};