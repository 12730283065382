<div>
    <div id="footer-bar" class="footer-bar-2 ml-3 mr-3 rounded-s" v-if="$store.state.user.user_type == 'partner'">

        <router-link :to="{name: 'dashboard-home'}" :class="[dashboardTabActive ? 'homeTab' : '']">
            <i class="fas fa-home tabIcon" :class="[dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']"></i>
            <span class="letterSpacing" :class="[ dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']">
                Home
            </span>
        </router-link>
        <router-link :to="{name: 'order-home'}" :class="[ordersTabActive ? 'orderTab' : '']">
            <i class="fas fa-shopping-bag tabIcon"
                :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']"></i>
            <span class="letterSpacing" :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']">
                Orders
            </span>
        </router-link>
        <a href="javascript:void(0)" data-menu="ActionSheet">
            <div class="actionBtn">
                <i class="fas fa-plus plusIcon"></i>
            </div>
        </a>
        <router-link :to="{name: 'passbook-statements'}" :class="[passbookTabActive ? 'notificationTab' : '']">
            <i class="fa fa-wallet tabIcon" :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']"></i>
            <span class="letterSpacing" :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']">
                Wallet
            </span>
        </router-link>
        <a href="javascript:void(0)" data-menu="menu-main">
            <i class="fas fa-bars NavDeActivate tabIcon"></i>
            <span class="NavDeActivate letterSpacing">
                Menu
            </span>
        </a>
    </div>



    <!-- partner_admin -->
    <!-- <div id="footer-bar" class="footer-bar-2 ml-3 mr-3 rounded-s" v-if="$store.state.user.user_type == 'partner_admin'">
        <a href="/dashboard">
            <i class="fas fa-home" :class="[dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']">
                Dashboard
            </span>
        </a>
        <a href="/orders">
            <i class="fas fa-shopping-basket" :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']">
                Orders
            </span>
        </a>
        <a href="/partners">
            <i class="fa fa-users" :class="[ partnersTabActive ? 'partnersNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ partnersTabActive ? 'partnersNavActivate' : 'NavDeActivate']">
                Partners
            </span>
        </a>
        <a href="/notifications">
            <i class="fa fa-bell" :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']">
                Notification
            </span>
        </a>

        <a href="#" data-menu="menu-main">
            <i class="fas fa-bars NavDeActivate"></i>
            <span class="NavDeActivate">
                Menu
            </span>
        </a>
    </div> -->



    <!-- cluster_admin -->
    <!-- <div id="footer-bar" class="footer-bar-2 ml-3 mr-3 rounded-s" v-if="$store.state.user.user_type == 'cluster_admin'">
        <a href="/dashboard" :class="[dashboardTabActive ? 'homeTab' : '']">
            <i class="fas fa-home" :class="[dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ dashboardTabActive ? 'homeNavActivate' : 'NavDeActivate']">
                Dashboard
            </span>
        </a>
        <a href="/orders">
            <i class="fas fa-shopping-basket" :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']">
                Orders
            </span>
        </a>
        <a href="">
            <i class="fa fa-heart" :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ ordersTabActive ? 'ordersNavActivate' : 'NavDeActivate']">
                Create Order
            </span>
        </a>
        <a href="/notifications">
            <i class="fa fa-bell" :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']"></i>
            <span :class="[ passbookTabActive ? 'notifyNavActivate' : 'NavDeActivate']">
                Notification
            </span>
        </a>
        <a href="#" data-menu="menu-main">
            <i class="fas fa-bars NavDeActivate"></i>
            <span class="NavDeActivate">
                Menu
            </span>
        </a>
    </div> -->
</div>


<!-- MAIN BOTTOM SLIDE START -->

<div id="ActionSheet" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-width="auto"
    data-menu-effect="menu-over" v-if="$store.state.user.user_type != 'super_admin'">
    <div class="actionSheetHeader mt-0 py-3">
        <a href="#" class="close-menu closeBtnAction">
            <i class="fa fa-times-circle closeIcon text-secondary"></i>
        </a>
        <h1 class="text-center text-capitalize font-300 font-18 m-0 p-0" style="letter-spacing: 2px;font-size: 20px;">
            quick actions
        </h1>
    </div>
    <div class="mt-4 mb-5 text-center">
        <div class="row mx-auto mb-0">
            <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                <a href="/orders/create" v-if="$route.name == 'order-create'"
                    class="icon icon-l rounded-xl mb-2 quickActionIconContainer">
                    <div class="IconWrapper">
                        <i class="fas fa-pen"></i>
                    </div>
                </a>
                <router-link :to="{name: 'order-create'}" v-if="$route.name != 'order-create'"
                    class="icon icon-l rounded-xl mb-2 quickActionIconContainer">
                    <div class="IconWrapper">
                        <i class="fas fa-pen"></i>
                    </div>
                </router-link>
                <p class="font-400 quickActionIconTitle">Create Order</p>
            </div>

            <div
                v-if="referral_link && $store.state.user.partnerType && parseFloat($store.state.user.partnerType.commission_percentage) > 0">
                <div class="d-flex justify-content-center">
                    <h3 class="font-15 font-200 letterSpacing seperateSectionHeading">Refer & Earn</h3>
                </div>
                <div class="customDivider"></div>
                <div class="row mx-auto mb-0">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-4" v-if="referral_link && referral_code">
                        <a href="javascript:void(0)" data-menu="ProductItemRefferalLinkMenu"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer">
                            <div class="IconWrapper">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </a>
                        <p class="font-400 quickActionIconTitle">Share Products</p>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                        <a href="javascript:void(0)" data-menu="shareableMobileSteviazItem"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer" v-if="$store.state.isMobile">
                            <div class="IconWrapper">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </a>
                        <a href="javascript:void(0)" @click="shareableDesktopSteviazItemAction" data-menu="shareableDesktopSteviazItem"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer" v-else>
                            <div class="IconWrapper">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </a>
                        <p class="font-400 quickActionIconTitle">Natural Stevia</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-center">
                    <h3 class="font-15 font-200 letterSpacing seperateSectionHeading">Ask Customer Feedback</h3>
                </div>
                <div class="customDivider"></div>
                <div class="row mx-auto mb-0">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                        <a href="javascript:void(0)" @click="customerFeedbackAction"
                            data-menu="CustomerFeedbackLinkMenu"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer">
                            <div class="IconWrapper">
                                <i class="far fa-comment-dots"></i>
                            </div>
                        </a>
                        <p class="font-400 quickActionIconTitle mb-0">
                            Ice cream
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MAIN BOTTOM SLIDE END -->


<!-- PRODUCTS LISTING BOTTOM MENU SLIDE START-->

<div id="ProductItemRefferalLinkMenu" class="menu menu-box-bottom rounded-m" data-menu-effect="menu-over">
    <div class="actionSheetHeader mt-0 py-3">
        <a href="#" class="close-menu closeBtnAction">
            <i class="fa fa-times-circle closeIcon text-secondary"></i>
        </a>
        <h1 class="text-center text-capitalize font-300 font-18 m-0 p-0" style="letter-spacing: 2px;font-size: 20px;">
            Select Product To Share
        </h1>
    </div>
    <div class="mt-4 mb-4 text-center" v-if="allProducts && allProducts.length > 0">
        <div class="row mx-auto mb-0">
            <div v-for="c in allProducts">
                <div class="d-flex justify-content-center">
                    <h3 class="font-15 font-200 letterSpacing seperateSectionHeading">{{ c.category }}</h3>
                </div>
                <div class="customDivider"></div>
                <div class="productItemReferWrapper">
                    <div v-for="(product,index) in c.products" :key="product.id">

                        <!-- FOR MOBILE DEVICE SHOW POPUP OPTION TO SHARE PRODUCT IN DIFF. SOCIAL MEDIA -->
                        <a href="javascript:void(0)" @click="closeDownSlide(product)"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer" v-if="$store.state.isMobile">
                            <div class="popularContainer">
                                <div class="popularWrapper" v-if="product.crm_data.Popular">
                                    <i class="fas fa-heart"></i>
                                    Popular
                                </div>
                            </div>
                            <div class="productItemImageWrapper" v-if="product.crm_data.Sharing_Image_URL">
                                <img :src="product.crm_data.Sharing_Image_URL" />
                            </div>
                            <div class="IconWrapper" v-else>
                                <i class="fas fa-link"></i>
                            </div>
                        </a>

                        <!-- FOR NON-MOBILE DEVICE SHARE PRODUCT IN DIFF. SOCIAL MEDIA -->
                        <a href="javascript:void(0)" @click="share(product)" :data-menu="product.id"
                            class="icon icon-l rounded-xl mb-2 quickActionIconContainer" v-else>
                            <div class="popularContainer">
                                <div class="popularWrapper" v-if="product.crm_data.Popular">
                                    <i class="fas fa-heart"></i>
                                    Popular
                                </div>
                            </div>
                            <div class="productItemImageWrapper" v-if="product.crm_data.Sharing_Image_URL">
                                <img :src="product.crm_data.Sharing_Image_URL" />
                            </div>
                            <div class="IconWrapper" v-else>
                                <i class="fas fa-link"></i>
                            </div>
                        </a>

                        <div class="quickActionIconTitle" v-if="product.Product_Name">
                            <p class="referProductItemTitle" v-if="product.Product_Name.indexOf('(') !== -1">
                                {{ product.Product_Name.slice(0,product.Product_Name.indexOf('(')) }}
                            </p>
                            <p class="referProductItemTitle" v-else>
                                {{ product.Product_Name }}
                            </p>
                            <p class="referProductItemSubTitle"
                                v-if="product.Product_Name.indexOf('(') !== -1 && product.Product_Name.indexOf(')') !== -1">
                                ({{ product.Product_Name.slice(product.Product_Name.indexOf('(')
                                +1,product.Product_Name.indexOf(')')) }})
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- PRODUCTS LISTING BOTTOM MENU SLIDE END-->

<!-- PRODUCT SHARABLE OPTION BOTTOM MENU SLIDE START-->

<div :id="UniqueProductItemReferLinkMenu" class="menu menu-box-bottom rounded-m" data-menu-height="320"
    data-menu-effect="menu-over">
    <!-- <input type="text" class="form-control copyProductItemLinkInput hide" v-if="Sharing_URL" :value="Sharing_URL"
        style="opacity: 0;position: absolute;"> -->
    <div class="menu-title">
        <h1 class="font-17" style="letter-spacing: 1px;font-weight: 200;width: 90%;"> Share {{ referedItemTitle || '' }}
        </h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle text-secondary"></i></a>
    </div>
    <div class="divider divider-margins mt-3 mb-0"></div>
    <div class="content mt-0">
        <div class="list-group list-custom-small list-icon-0">

            <a href="#" class="external-link close-menu" @click="copyProductItemReferLinkAction(referedItemTitle)">
                <i class="fa fa-copy font-12 bg-success color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Copy</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="whatsappLink" class="external-link">
                <i class="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">WhatsApp</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="facebookLink" class="external-link">
                <i class="fab fa-facebook-f font-12 bg-facebook color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Facebook</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="emailLink" class="external-link border-0">
                <i class="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Email</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
        </div>
    </div>
</div>

<!-- PRODUCT SHARABLE OPTION BOTTOM MENU SLIDE END-->



<!-- CUSTOMER FEEDBACK BOTTOM MENU SLIDE START-->

<div v-if="customer_feedback_url" id="CustomerFeedbackLinkMenu" class="menu menu-box-bottom rounded-m"
    data-menu-height="320" data-menu-effect="menu-over">
    <div class="menu-title">
        <h1 class="font-17" style="letter-spacing: 1px;font-weight: 200;">Share Customer Feedback</h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle text-secondary"></i></a>
    </div>
    <div class="divider divider-margins mt-3 mb-0"></div>
    <div class="content mt-0">
        <div class="list-group list-custom-small list-icon-0">

            <a href="#" class="external-link close-menu" @click="copyCustomerFeedbackLinkAction">
                <i class="fa fa-copy font-12 bg-success color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Copy</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="whatsappLink" class="external-link">
                <i class="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">WhatsApp</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="facebookLink" class="external-link">
                <i class="fab fa-facebook-f font-12 bg-facebook color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Facebook</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="emailLink" class="external-link border-0">
                <i class="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Email</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
        </div>
    </div>
</div>

<!-- CUSTOMER FEEDBACK BOTTOM MENU SLIDE END-->


<div id="shareableIceCreamItem" class="menu menu-box-modal menu-box-round-medium menu-box-detached rounded-s"
    data-menu-height="250" data-menu-width="310" data-menu-effect="menu-over" data-menu-select="page-components">
    <!-- add data-cookie-activate above to auto-activate the menu on cookie detection -->
    <div class="boxed-text-xl">
        <h2 class="text-uppercase text-center mt-4 mb-5 font-300 font-17" style="letter-spacing: 1px;">Share With</h2>
        <!-- add hide-cookie to the class to delete the cookie-->
        <div class="SharingAction">
            <button @click="share(selectedProduct)" class="close-menu personalGroupShareButton">
                <i class="fab fa-whatsapp font-30 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span>Contact & Group</span>
            </button>
            <button @click="copyContent_DownloadImg(selectedProduct)" class="close-menu broadCastShareButton">
                <i class="fab fa-whatsapp font-30 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span>Broadcast</span>
            </button>
        </div>
    </div>
</div>

<div id="shareableMobileSteviazItem" class="menu menu-box-modal menu-box-round-medium menu-box-detached rounded-s"
    data-menu-height="250" data-menu-width="310" data-menu-effect="menu-over" data-menu-select="page-components">
    <!-- add data-cookie-activate above to auto-activate the menu on cookie detection -->
    <div class="boxed-text-xl">
        <h2 class="text-uppercase text-center mt-4 mb-5 font-300 font-17" style="letter-spacing: 1px;">Share With</h2>
        <!-- add hide-cookie to the class to delete the cookie-->
        <div class="SharingAction">
            <button @click="shareSteviaLinkWithSocialMedia" class="close-menu personalGroupShareButton">
                <i class="fab fa-whatsapp font-30 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span>Contact/Group</span>
            </button>
            <button @click="copySteviaContent_DownloadImg" class="close-menu broadCastShareButton">
                <i class="fab fa-whatsapp font-30 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span>Broadcast</span>
            </button>
        </div>
    </div>
</div>

<div id="shareableDesktopSteviazItem" class="menu menu-box-bottom rounded-m"
    data-menu-height="320" data-menu-effect="menu-over">
    <div class="menu-title">
        <h1 class="font-17" style="letter-spacing: 1px;font-weight: 200;">Share Natural Steviaz</h1>
        <a href="#" class="close-menu"><i class="fa fa-times-circle text-secondary"></i></a>
    </div>
    <div class="divider divider-margins mt-3 mb-0"></div>
    <div class="content mt-0">
        <div class="list-group list-custom-small list-icon-0">

            <a href="#" class="external-link close-menu" @click="copySteviaReferLinkAction">
                <i class="fa fa-copy font-12 bg-success color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Copy</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="whatsappLink" class="external-link">
                <i class="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">WhatsApp</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="facebookLink" class="external-link">
                <i class="fab fa-facebook-f font-12 bg-facebook color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Facebook</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
            <a target="_blank" :href="emailLink" class="external-link border-0">
                <i class="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-circle"></i>
                <span class="letterSpacing font-500">Email</span>
                <i class="fa fa-angle-right pr-1"></i>
            </a>
        </div>
    </div>
</div>