import router from "@/router";
import store from "@/store";
import helper from "@/helper";
import BaseClass from "@/common/classes/base-class";

/** https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards */

export default new class extends BaseClass {

    register() {
        router.beforeEach((to, from, next) => {

            let promises = [new Promise(resolve => {
                resolve();
            })];
            if (!store.state.selectData) {
                promises.push(helper.getSelectData({ 'type': ['phoneCodes', 'countries'] }));
            }
            if (!store.state.sessionLoaded) {
                helper.setupAuthListeners();
                promises.push(helper.validateAuthorization());
                store.dispatch("setStateKey", {
                    key: "sessionLoaded",
                    value: true
                });
            }

            Promise.all(promises).then(() => {
                var nextConfig = null;

                if (!to.meta.reqAuth && to.name == "login" && store.state.user) {
                    nextConfig = {
                        name: "dashboard-home"
                    };
                } else if (to.meta.reqAuth && !store.state.user) {
                    nextConfig = {
                        name: "login"
                    };
                } else if (to.meta.reqAuth) {
                    var permissions = store.state.user.permissions || [];
                    if (to.meta.permission && permissions.indexOf(to.meta.permission) < 0) {
                        nextConfig = {
                            name: "dashboard-home"
                        }; // redirect to page not access
                        this.$popper.error("You don't have permission to view this page");
                    }
                }

                this.showLoader();
                next(nextConfig);
            });
        });

        router.afterEach((to /*, from*/) => {

            if (to.matched.some(record => !record.meta.manualHide)) {
                this.hideLoader();
            }
            setTimeout(() => {
                window.init_template();
            }, 500);
        });
    }
}();