import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getPassbookUsers(data) {
        return api.instance.get("/passbook-users", {
            params: data
        });
    }

    getPassbookStatments(data) {
        return api.instance.get("/passbook", {
            params: data
        });
    }

    export(data) {
        return api.instance.get('/passbook', { responseType: 'blob', params: data });
    }

    getStatementDetail(data) {
        return api.instance.get("/passbook/" + data.id);
    }

    addBonus(data) {
        return api.instance.post("passbook/add-bonus", data);
    }

    addPenalty(data) {
        return api.instance.post("passbook/add-penalty", data);
    }

    getRewardsPointStatements(data) {
        return api.instance.get("/reward-statements", {
            params: data
        });
    }

    getRewardsPointStatementDetail(id) {
        return api.instance.get("/reward-statements/" + id);
    }

    getRewardBalance(data) {
        return api.instance.get("/reward-statements/balance", {
            params: data
        });
    }

};