import {
    createApp
} from 'vue'
import App from './App/'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixins from "./common/mixins";
import guards from "./common/guards";
import services from "./common/services";
import components from "./common/components";
import './modules'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


const app = createApp(App).use(store).use(router);
mixins.register(app);
services.register(app);
guards.register(app);
components.register(app);

if (location.host=='partner.lifely.world') {
    Sentry.init({
        app,
        dsn: "https://fb99566dcc1647739a4f9a238bc55f9c@o1099842.ingest.sentry.io/6388755",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [ "partner.lifely.world", /^\// ],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

app.mount('#page')
