import router from '@/router';

[{
        path: '/commissions',
        name: "commission-home",
        component: () => import( /* webpackChunkName: "commission" */ './views/CommissionHome'),
        meta: {
            reqAuth: true,
            permission: 'commission_read'
        },
    },
    {
        path: '/commissions/:id',
        name: "commission-detail",
        component: () => import( /* webpackChunkName: "commission" */ './views/CommissionDetail'),
        meta: {
            reqAuth: true,
            permission: 'commission_read'
        },
    }
].forEach(router.addRoute);