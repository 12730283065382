import router from '@/router';

[{
        path: '/partner-admins',
        name: "partner-admin-home",
        component: () => import( /* webpackChunkName: "partner-admin" */ './views/PartnerAdminHome'),
        meta: {
            reqAuth: true,
            permission: 'partner_admin_read'
        },
    },
    {
        path: '/partner-admins/create',
        name: "partner-admin-create",
        component: () => import( /* webpackChunkName: "partner-admin" */ './views/CreatePartnerAdmin'),
        meta: {
            reqAuth: true,
            permission: 'partner_admin_create'
        },
    },
    {
        path: '/partner-admins/:id',
        name: "partner-admin-detail",
        component: () => import( /* webpackChunkName: "partner-admin" */ './views/PartnerAdminDetail'),
        meta: {
            reqAuth: true,
            permission: 'partner_admin_read'
        },
    },
    {
        path: '/partner-admins/edit/:id',
        name: "partner-admin-edit",
        component: () => import( /* webpackChunkName: "partner-admin" */ './views/EditPartnerAdmin'),
        meta: {
            reqAuth: true,
            permission: 'partner_admin_update'
        },
    },
    {
        path: '/partner-admin-register',
        name: "partner-admin-register",
        component: () => import( /* webpackChunkName: "partner-admin" */ './views/PartnerAdminRegister'),
        meta: {
            reqAuth: false,
        },
    }
].forEach(router.addRoute);