import router from "../../router"
export default new class {

    notificationRouteMappings = Object.freeze({
        'commissions': 'commission-detail',
        'tickets' : 'ticket-detail',
        'payments': 'payment-details',
        'passbook': 'passbook-statements',
        'customers': 'customer-details',
        'profile': 'profile',

        'cluster-admins' : 'cluster-admin-detail',
        'partner-admins' : 'partner-admin-detail',
        'partners' : 'partner-detail',
    });
    routeNotification(data) {
        router.push({
            name: this.notificationRouteMappings[data.module ? data.module : ""],
            params: {
                id: data.module_id ? data.module_id : ""
            }
        })
    }
}