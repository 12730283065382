import router from '@/router';

[ {
    path: '/orders',
    name: "order-home",
    component: () => import( /* webpackChunkName: "orders" */ './views/OrderHome'),
    meta: {
        reqAuth: true,
        permission: 'order_read'
    },
},
{
    path: '/orders/create',
    name: "order-create",
    component: () => import( /* webpackChunkName: "orders" */ './views/OrderCreate'),
    meta: {
        reqAuth: true,
        permission: 'order_read'
    }
},
{
    path: '/orders/:id',
    name: "order-detail",
    component: () => import( /* webpackChunkName: "orders" */ './views/OrderDetail'),
    meta: {
        reqAuth: true,
        permission: 'order_read'
    },
},
{
    path: '/orders/success/:id',
    name: "order-success",
    component: () => import( /* webpackChunkName: "orders" */ './views/OrderSuccess'),
    meta: {
        reqAuth: true,
        permission: 'order_read'
    },
}

].forEach(router.addRoute);