export default {
    props: [ 'modelValue', 'options', 'valueProp'],
    emits: [ 'update:modelValue' ],
    data() {
        return {
            componentId: Math.random().toString(36).substring(2, 7),
            search: null,
            selected: this.modelValue,
            multiSelected: [],
            isMultiple: false,
            disableSearchBar: false,
            disableClearSelection: false
        }
    },
    created(){
        if('disableSearchBar' in this.$attrs){
            this.disableSearchBar = this.$attrs.disableSearchBar;
        }
        if('disableClearSelection' in this.$attrs){
            this.disableClearSelection = this.$attrs.disableClearSelection;
        }
    },
    watch: {
        'modelValue': function () {
            setTimeout(() => {
                if (this.isMultiple) {
                    this.multiSelected = this.modelValue;
                }
                this.selected = this.modelValue;
                this.$el.parentElement.querySelector('.app-select').value = this.modelValue || '';
            }, 1000);
        },
        'options': function () {
            setTimeout(() => {
                this.$el.parentElement.querySelector('.app-select').value = this.modelValue || '';
            }, 1000);
        },
        'selected': function () {
            this.$emit('update:modelValue', this.isMultiple ? this.multiSelected : this.selected)
        },
        'search': function () {
            this.$refs.opts.children.forEach(element => {
                if (this.search) {
                    element.classList.add('d-none');
                    if (element.innerText.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                        element.classList.remove('d-none');
                    }
                } else {
                    element.classList.remove('d-none');
                }
            });
        }
    },
    computed: {
        listValues() {
            if (this.search) {
                return this.options.filter((value) => {
                    var itemName = this.type == "phoneCode" ? value.name : value.text;
                    return (itemName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                })
            } else {
                return this.options;
            }
        }
    },
    mounted() {
        this.isMultiple = ('multiple' in this.$attrs);
    },
    methods: {
        openModal() {
            this.search = null;
        },
        selectOption(selected) {
            this.selected = this.valueProp ? selected[ this.valueProp ] : selected;
            if (!this.isMultiple) {
                this.$refs.closeBtn.click();
            } else {
                if (this.multiSelected.includes(this.selected)) {
                    this.multiSelected.splice(this.multiSelected.indexOf(this.selected), 1);
                } else {
                    this.multiSelected.push(this.selected);
                }
            }
        },
        clearSelect() {
            this.selected = null;
            this.multiSelected = [];
            this.$refs.closeBtn.click();
        }
    }
}