import axios from "axios";
import popper from "@/common/services/popper";
import appConfig from "@/common/services/app-config";


export default class BaseClass {

    constructor() {
        this.$axios = axios;
        this.$popper = popper;
        this.$appConfig = appConfig;
    }

    showLoader(transBg) {
        var preloader = document.getElementById('preloader')
        if (preloader) {
            preloader.classList.remove('preloader-hide');
            if (transBg) {
                preloader.classList.add('transparent');
            }
        }

    }

    hideLoader() {
        var preloader = document.getElementById('preloader')
        if (preloader) {
            preloader.classList.add('preloader-hide');
            preloader.classList.remove('transparent');
        }

    }

    /**
     * https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
     */
    copyToClipboard(text) {
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return Promise.resolve();
        }
        return navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }
}