import router from '@/router';

[{
        path: '/tickets',
        name: "ticket-home",
        component: () => import( /* webpackChunkName: "partners" */ './views/TicketHome'),
        meta: {
            reqAuth: true,
            permission: 'ticket_read'
        },
    },
    {
        path: '/tickets/create',
        name: "ticket-create",
        component: () => import( /* webpackChunkName: "partners" */ './views/CreateTicket'),
        meta: {
            reqAuth: true,
            permission: 'ticket_create'
        },
    },
    {
        path: '/tickets/:id',
        name: "ticket-detail",
        component: () => import( /* webpackChunkName: "partners" */ './views/TicketDetail'),
        meta: {
            reqAuth: true,
            permission: 'ticket_read'
        },
    },

].forEach(router.addRoute);