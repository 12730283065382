/* eslint-disable no-unused-vars */
/**
 * Slot example :
 *   <h1 class="header-title">AppKit</h1>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-2"><i class="fas fa-share-alt"></i></a>
 *   <a href="#" data-menu="menu-share" class="header-icon header-icon-3"><i class="fas fa-share-alt"></i></a>
 */

import appConfig from "@/common/services/app-config";
import helper from "@/helper";
import sharableProductsService from "./services";
import _, { map } from 'underscore';
export default {
    data() {
        return {
            dashboardTabActive: false,
            ordersTabActive: false,
            passbookTabActive: false,
            partnersTabActive: false,
            hasSuperAdmin: false,
            customer_feedback_url: "",
            whatsappLink: '',
            facebookLink: '',
            emailLink: '',
            linkedinLink: '',
            twitterLink: '',
            allProducts: [],
            UniqueProductItemReferLinkMenu: '',
            referedItemTitle: '',
            Sharing_Content: '',
            Sharing_URL: '',
            referral_code: '',
            steviaReferLink: '',
            referral_link: '',
            selectedProduct: '',
            selectedProductImage: '',
            downloadableImgName: ''
        }
    },
    mounted() {
        window.init_menu();
        this.getProducts();
    },
    computed: {
        'user': function () {
            return this.$store.state.user;
        }
    },
    created() {
        if ('user' in this.$store.state && this.$store.state.user != null && this.$store.state.user.user_type != 'super_admin') {
            this.customer_feedback_url = appConfig.customerFeedback + this.$store.state.user.email;
            if (this.$store.state.user.referral_link) {
                this.referral_link = this.$store.state.user.referral_link;
                this.referral_code = this.referral_link.split('/')[ this.referral_link.split('/').length - 1 ];
                if (this.referral_link && this.referral_code) {
                    this.steviaReferLink = appConfig.storeUrl + 'products/stevia.html?referral_code=' + this.referral_code;
                } else {
                    this.steviaReferLink = appConfig.storeUrl + 'products/stevia.html';
                }
            }
        }
        this.checkActiveTab();

    },
    watch: {
        'Sharing_URL': function () {
            if ('user' in this.$store.state && this.$store.state.user != null && this.$store.state.user.referral_link) {

                if (this.referral_code !== '' && this.Sharing_URL !== '') {
                    this.whatsappLink = "whatsapp://send?text=" + this.Sharing_URL;
                    this.facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + this.Sharing_URL;
                    this.emailLink = "mailto:?body=" + this.Sharing_URL;
                    this.linkedinLink = "https://www.linkedin.com/shareArticle?mini=true&url=" + this.Sharing_URL;
                    this.twitterLink = "https://twitter.com/home?status=" + this.Sharing_URL;
                }

            }
        }
    },

    methods: {
        checkActiveTab() {
            if (location.pathname.toLowerCase().indexOf('/dashboard') !== -1) {
                this.dashboardTabActive = true;
                this.ordersTabActive = false;
                this.passbookTabActive = false;
                this.partnersTabActive = false;
            }
            if (location.pathname.toLowerCase().indexOf('/orders') !== -1) {
                this.ordersTabActive = true;
                this.dashboardTabActive = false;
                this.passbookTabActive = false;
                this.partnersTabActive = false;
            }
            if (location.pathname.toLowerCase().indexOf('/passbook') !== -1) {
                this.passbookTabActive = true;
                this.dashboardTabActive = false;
                this.ordersTabActive = false;
                this.partnersTabActive = false;
            }
            if (location.pathname.toLowerCase().indexOf('/reward-statements') !== -1) {
                this.passbookTabActive = true;
                this.dashboardTabActive = false;
                this.ordersTabActive = false;
                this.partnersTabActive = false;
            }
            if (location.pathname.toLowerCase().indexOf('/partners') !== -1) {
                this.partnersTabActive = true;
                this.dashboardTabActive = false;
                this.ordersTabActive = false;
                this.passbookTabActive = false;
            }
        },

        getProducts() {
            sharableProductsService.getProducts({ 'sharable': true }).then(response => {
                this.hideLoader();
                const category = _.groupBy(response.data, function (i) { return i.crm_data.Product_Category });
                this.allProducts = [];
                Object.keys(category).forEach(key => {
                    this.allProducts.push({ "category": key, "products": category[ key ] });
                });

                this.allProducts = this.allProducts.sort((a, b) => {
                    var nA = a.category.toLowerCase();
                    var nB = b.category.toLowerCase();

                    if (nA < nB) {
                        return -1;
                    }
                    else if (nA > nB) {
                        return 1;
                    }
                    return 0;
                });
            });
        },

        closeDownSlide(product) {
            this.selectedProduct = product;
            this.selectedProductImage = product.crm_data.Sharing_Image_URL;
            if (this.selectedProductImage) {
                this.downloadableImgName = this.selectedProductImage.substring(this.selectedProductImage.lastIndexOf('/') + 1);
            } else {
                this.downloadableImgName = ''
            }


            const productListMenu = document.getElementById('ProductItemRefferalLinkMenu');
            const shareableIceCreamItemMenu = document.getElementById("shareableIceCreamItem");
            if (productListMenu) {
                document.getElementById('ProductItemRefferalLinkMenu').classList.remove('menu-active');
                if (shareableIceCreamItemMenu) {
                    document.getElementById("shareableIceCreamItem").classList.add('menu-active');
                }
            }
        },

        copyContent_DownloadImg(product) {
            this.showLoader(true);
            if (product && product.crm_data) {
                if (product.crm_data.Sharing_Image_URL) {
                    fetch(this.selectedProductImage)
                        .then(resp => resp.blob())
                        .then(blobobject => {
                            const blob = window.URL.createObjectURL(blobobject);
                            const anchor = document.createElement('a');
                            anchor.style.display = 'none';
                            anchor.href = blob;
                            anchor.download = this.downloadableImgName;
                            document.body.appendChild(anchor);
                            anchor.click();
                            window.URL.revokeObjectURL(blob);
                            this.hideLoader();
                        })
                        .catch(() => console.log('An error in downloading the file sorry'));

                }
                if (product.crm_data.Sharing_URL) {
                    const link = document.createElement("input");
                    link.value = product.crm_data.Sharing_URL + '?referral_code=' + this.referral_code;
                    document.body.appendChild(link);
                    link.select();
                    document.execCommand('copy');
                    document.body.removeChild(link);
                    this.$popper.success('Content Copied');
                    this.hideLoader();

                }
                if (!product.crm_data.Sharing_URL && !product.crm_data.Sharing_Image_URL) {
                    this.$popper.error('Content and Image is not available');
                    this.hideLoader();
                }
                if (product.crm_data.Sharing_URL && !product.crm_data.Sharing_Image_URL) {
                    this.$popper.error('Image is not available');
                    this.hideLoader();
                }
                if (!product.crm_data.Sharing_URL && product.crm_data.Sharing_Image_URL) {
                    this.$popper.error('Content is not available');
                    this.hideLoader();
                }
            }
        },

        share(product) {
            this.Sharing_URL = product.crm_data.Sharing_URL ?
                product.crm_data.Sharing_URL + '?referral_code=' + this.referral_code : null;
            this.referedItemTitle = product.Product_Name;
            //checking availability of navigator.share or sharing image url
            if (!('share' in navigator)) {
                //checking availability of sharing url
                if (this.Sharing_URL) {
                    this.UniqueProductItemReferLinkMenu = product.id;
                } else {
                    this.UniqueProductItemReferLinkMenu = '';
                    this.$popper.error('Sorry,Referral Link for selected product is currently not available');
                }
            }
            else {
                if (this.Sharing_URL && product.crm_data.Sharing_Image_URL !== '') {
                    this.Sharing_Content = product.crm_data.Sharing_Content;
                    this.Sharing_Content += "\n\n✂ *Referral Code* -" + " *" + this.referral_code + "*";
                    this.Sharing_Content += "\n\n🛒 *Order Now* \n" + this.Sharing_URL;
                    this.Sharing_Content += "\n⬇️ *Download Android App* \n https://play.google.com/store/apps/details?id=world.lifely";
                    let navigatorShareData = {
                        imageUrl: product.crm_data.Sharing_Image_URL,
                        imageTitle: 'lifely-product.png',
                        shareTitle: this.referedItemTitle,
                        shareText: this.Sharing_Content,
                    }
                    helper.navigatorShare(navigatorShareData);
                }
                if (!this.Sharing_URL || product.crm_data.Sharing_Image_URL === '') {
                    this.Sharing_Content = "✌️ *Lifely Natural & Healthy Superfood Store* \n";
                    this.Sharing_Content += "\n🚫 No Added Sugar";
                    this.Sharing_Content += "\n🚫 No Artificial Sweeteners";
                    this.Sharing_Content += "\n🚫 No Color/chemical/Preservatives";
                    this.Sharing_Content += "\n🚫 No Palm fat";
                    this.Sharing_Content += "\n👌 Naturally sweetened with pure stevia";
                    this.Sharing_Content += "\n👌 Rich with prebiotic dietary fibers";
                    this.Sharing_Content += "\n👌 Real ingredients & natural taste";
                    this.Sharing_Content += "\n\n🛒 *Signup Now* \n" + this.referral_link + "\n";
                    this.Sharing_Content += "✂ *Referral Code* -" + " *" + this.referral_code + "*";
                    this.Sharing_Content += "\n⬇️ *Download Android App* \n https://play.google.com/store/apps/details?id=world.lifely";

                    let navigatorShareData = {
                        imageUrl: "/images/lifely-logo.png",
                        imageTitle: 'lifely.png',
                        shareTitle: this.referedItemTitle,
                        shareText: this.Sharing_Content,
                    }
                    helper.navigatorShare(navigatorShareData);
                }
            }
        },

        shareSteviaLinkWithSocialMedia() {
            if (this.referral_link && this.referral_code) {
                this.steviaReferLink = appConfig.storeUrl + 'products/stevia.html?referral_code=' + this.referral_code;
            } else {
                this.steviaReferLink = appConfig.storeUrl + 'products/stevia.html';
            }

            if (!('share' in navigator)) {
                this.$popper.error('Sorry,Sharing not supported');
                return null;
            } else {
                this.Sharing_Content = "✌️ *Lifely 100% Pure Natural Sweetener(SteviaZ)* \n";
                this.Sharing_Content += "\n🚫 No Added Sugar(any of 65+ types)";
                this.Sharing_Content += "\n🚫 No Artificial Sweeteners";
                this.Sharing_Content += "\n🚫 No Chemical/Solvant used in extraction process";
                this.Sharing_Content += "\n🚫 No Erythritol/Maltitol/Sucrose/Dextrose/Maltodextrin/Aspartame used";
                this.Sharing_Content += "\n👌 100% pure water based stevia extraction process";
                this.Sharing_Content += "\n👌 100% replacement of any type of sugar/honey";
                this.Sharing_Content += "\n👌 0 Glycemic index";
                this.Sharing_Content += "\n👌 Rich with prebiotic dietary fibers(Gut/Immune Health)";
                this.Sharing_Content += "\n👌 Wellness ingredients(Weight loss &  gluco balance)";
                this.Sharing_Content += "\n\n🛒 *Order Now* \n" + this.steviaReferLink + "\n";
                if (this.referral_code) {
                    this.Sharing_Content += "✂ *Referral Code* -" + " *" + this.referral_code + "*";
                }
                this.Sharing_Content += "\n⬇️ *Download Android App* \n https://play.google.com/store/apps/details?id=world.lifely";

                let navigatorShareData = {
                    imageUrl: "images/products/stevia/steviaz-jar.png",
                    imageTitle: 'lifely-steviaz-jar.png',
                    shareTitle: 'SteviaZ Natural Sweetener',
                    shareText: this.Sharing_Content,
                }

                helper.navigatorShare(navigatorShareData);
            }
        },

        copySteviaContent_DownloadImg() {
            this.showLoader(true);
            const link = document.createElement("input");
            link.value = this.steviaReferLink;
            document.body.appendChild(link);
            link.select();
            document.execCommand('copy');
            document.body.removeChild(link);
            this.$popper.success('Content Copied');
            this.hideLoader();

            fetch('/images/products/stevia/steviaz-jar.png')
                .then(resp => resp.blob())
                .then(blobobject => {
                    const blob = window.URL.createObjectURL(blobobject);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blob;
                    anchor.download = 'Natural-Stevia';
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blob);
                    this.$popper.success('Download Image');
                    this.hideLoader();
                })
                .catch(() => console.log('An error in downloading the file sorry'));



        },

        customerFeedbackAction() {
            this.whatsappLink = "whatsapp://send?text=" + this.customer_feedback_url;
            this.facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + this.customer_feedback_url;
            this.emailLink = "mailto:?body=" + this.customer_feedback_url;
            this.linkedinLink = "https://www.linkedin.com/shareArticle?mini=true&url=" + this.customer_feedback_url;
            this.twitterLink = "https://twitter.com/home?status=" + this.customer_feedback_url;
        },

        shareableDesktopSteviazItemAction() {
            this.whatsappLink = "whatsapp://send?text=" + this.steviaReferLink;
            this.facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + this.steviaReferLink;
            this.emailLink = "mailto:?body=" + this.steviaReferLink;
            this.linkedinLink = "https://www.linkedin.com/shareArticle?mini=true&url=" + this.steviaReferLink;
            this.twitterLink = "https://twitter.com/home?status=" + this.steviaReferLink;
        },

        copySteviaReferLinkAction() {
            const link = document.createElement("input");
            link.value = this.steviaReferLink;
            document.body.appendChild(link);
            link.select();
            document.execCommand('copy');
            document.body.removeChild(link);
            this.$popper.success('Stevia Referral Link Copied');
        },
        copyProductItemReferLinkAction(itemTitle) {
            const link = document.createElement("input");
            link.value = this.Sharing_URL;
            document.body.appendChild(link);
            link.select();
            document.execCommand('copy');
            document.body.removeChild(link);
            this.$popper.success(itemTitle + ' Item Referral Link Copied');
        },
        copyCustomerFeedbackLinkAction() {
            const link = document.createElement("input");
            link.value = this.customer_feedback_url;
            document.body.appendChild(link);
            link.select();
            document.execCommand('copy');
            document.body.removeChild(link);
            this.$popper.success('Customer Feedback Link Copied');
        }
    }
}