import router from '@/router';

[{
        path: '/customers',
        name: "customer-home",
        component: () => import( /* webpackChunkName: "customer" */ './views/CustomerHome'),
        meta: {
            reqAuth: true,
            permission: 'customer_read'
        },
    },
    {
        path: '/customers/:id',
        name: "customer-detail",
        component: () => import( /* webpackChunkName: "customer" */ './views/CustomerDetail'),
        meta: {
            reqAuth: true,
            permission: 'customer_read'
        },
    }
].forEach(router.addRoute);